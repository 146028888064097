const init = function() {
  const trigger = document.querySelectorAll('[data-mobile-menu-trigger]');

  const close = selector => {
    const menu = document.querySelector(selector);
    if (menu) {
      menu.classList.remove('active');
      document.body.classList.remove('noscroll');
      trigger.forEach(t => {
        t.classList.remove('is-active');
      });
    }
  };

  const open = selector => {
    const menu = document.querySelector(selector);
    if (menu) {
      menu.classList.add('active');
      document.body.classList.add('noscroll');

      menu.querySelectorAll('a').forEach(a => {
        a.addEventListener('click', () => {
          close(selector);
        });
      });
    }
  };

  trigger.forEach(t => {
    t.addEventListener('click', e => {
      e.preventDefault();
      t.classList.toggle('is-active');
      if (t.classList.contains('is-active')) {
        open(t.dataset.mobileMenuTrigger);
      } else {
        close(t.dataset.mobileMenuTrigger);
      }
    });
  });
};

export default { init };
