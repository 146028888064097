/* eslint-disable no-unused-vars */
import anime from 'animejs';
import ScrollOut from 'scroll-out';

const init = function() {
  const definitions = {
    '.heading': [
      {
        targets: `.w`,
        opacity: [0.06, 1], // 6% / 4%
        easing: 'easeInOutQuad',
        duration: 1500,
        delay(_el, i) {
          return 220 * (i + 1); // + 500;
        }
      }
    ],
    '.subtext': [
      {
        opacity: [0, 1],
        translateX: [20, 0],
        easing: 'easeOutQuad',
        duration: 500,
        delay(_el, i) {
          return 250 * (i + 1); // + 500;
        }
      },
      {
        targets: `em > span`, // red line under em
        scaleX: [0, 1],
        easing: 'easeOutCubic',
        duration: 1000,
        offset: '+=300'
      }
    ],
    '.animate-photo': [
      {
        scaleX: [0, 1],
        easing: 'easeOutQuad',
        duration: 300,
        delay: 0
      },
      {
        targets: `img`,
        opacity: [0, 1],
        translateX: [-10, 0],
        easing: 'easeOutQuad',
        duration: 500,
        offset: '+=100'
      }
    ],
    '.btn': [
      {
        scaleX: [0, 1],
        opacity: 1,
        easing: 'easeOutQuad',
        duration: 300,
        delay: 0
      },
      {
        targets: `span`,
        opacity: [0, 1],
        translateX: [-20, 0],
        offset: '+=100'
      },
      {
        targets: `img`,
        opacity: [0, 1],
        translateX: [-20, 0],
        translateY: ['-50%', '-50%'],
        offset: '-=800'
      }
    ]
  };
  definitions['.animate-text'] = definitions['.subtext'];

  /* --- headings (type by word) --- */

  // Wrap every word (anything-then-space) in a span
  [...document.querySelectorAll('.heading')].map(elem => {
    elem.innerHTML = elem.innerHTML.replace(/[^ ]+ /g, '<span class="w">$&</span> ');
    return elem;
  });

  /* --- in viewport triggers --- */
  const initScrollOut = () => {
    ScrollOut({
      targets: `.animate`,
      once: true,

      onShown(_element, _ctx, _scrollingElement) {
        const anim_ = definitions[`.${_element.classList.value.split(' ')[0]}`];

        if (anim_) {
          let t = anime.timeline({ loop: false, autoplay: true });
          if (!anim_[0].opacity || anim_[0].targets) {
            // show element first, if no opacity defined (or subtarget defined)
            t = t.add({
              targets: _element,
              opacity: 1,
              easing: 'easeInOutQuad',
              duration: 1
            });
          }
          anim_.map((anim, i) => {
            t = t.add(
              {
                ...anim,
                targets: anim.targets ? _element.querySelectorAll(anim.targets) : _element
              },
              anim.offset ? anim.offset : i === 0 ? null : null /* '+=500' */
            );
            return true;
          });
          t.play();
        }
      },
      onHidden(_element, _ctx, _scrollingElement) {
        /* Triggered when an element is hidden */
      },
      onChange(_element, _ctx, _scrollingElement) {
        /* Triggered when an element changes visibility */
      }
    });
  };

  setTimeout(initScrollOut, 1000); // start animations after 1s
};

export default { init };
