import ScrollOut from 'scroll-out';

const init = function() {
  const menuContainer = document.getElementById('menu-container');
  const indicator = document.querySelector('[data-progress-indicator]');

  const setActiveMenuItem = elem => {
    const progress =
      (elem.offsetLeft + elem.offsetWidth / 2) / elem.parentElement.parentElement.offsetWidth;
    if (progress) {
      indicator.style.transform = `scaleX(${progress})`;
      indicator.style.webkitTransform = `scaleX(${progress})`;
    }
  };

  ScrollOut({
    targets: `section, footer`,
    threshold: 0.5,
    onShown(_element) {
      const menuElem = menuContainer.querySelector(`[href="#${_element.id}"]`);
      if (menuElem) {
        setActiveMenuItem(menuElem);
      }
    }
  });
};

export default { init };
