const init = function() {
  const DISPLAY_TIME_MS = 3000;

  const container = document.getElementById('header__logo');
  const images = container.querySelectorAll('img');

  const activateImage = i => {
    images[i].classList.add('active');
    setTimeout(function() {
      images[i].classList.remove('active');
      activateImage(i < images.length - 1 ? i + 1 : 0);
    }, DISPLAY_TIME_MS);
  };

  if (images.length) {
    activateImage(0);
  }
};

export default { init };
