// import 'babel-polyfill';
// import './vendor/polyfills';
import smoothscroll from 'smoothscroll-polyfill';

import Preloader from './modules/preloader';
import Animations from './modules/animations';
import Cover from './modules/cover';
import RotateImages from './modules/rotateImages';
import Modal from './modules/modal';
import MobileMenu from './modules/mobileMenu';
import Progress from './modules/progress';

window.addEventListener('load', () => {
  Preloader.hide();
  Cover.init();
  Animations.init();
  RotateImages.init();
  Modal.init();
  MobileMenu.init();
  Progress.init();
});

smoothscroll.polyfill();
