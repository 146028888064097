const init = function() {
  // Init
  const container = document.getElementById('why');
  const cover = document.getElementById('cover_container');

  // Mouse
  const mouse = {
    _x: 0,
    _y: 0,
    x: 0,
    y: 0,
    updatePosition(event) {
      const e = event || window.event;
      this.x = e.clientX - this._x;
      this.y = (e.clientY - this._y) * -1;
    },
    setOrigin(e) {
      this._x = e.offsetLeft + Math.floor(e.offsetWidth / 2);
      this._y = e.offsetTop + Math.floor(e.offsetHeight / 2);
    },
    show() {
      return `(${this.x}, ${this.y})`;
    }
  };

  // Track the mouse position relative to the center of the container.
  mouse.setOrigin(container);

  //-----------------------------------------

  let counter = 0;
  const updateRate = 10;
  const isTimeToUpdate = function() {
    return counter++ % updateRate === 0;
  };

  //-----------------------------------------

  const updateTransformStyle = function(x, y) {
    const style = `rotateX(${x}deg) rotateY(${y}deg)`;
    cover.style.transform = style;
    cover.style.webkitTransform = style;
    cover.style.mozTransform = style;
    cover.style.msTransform = style;
    cover.style.oTransform = style;
  };

  const percentageToDeg = function(perc) {
    return perc * 2;
  };

  const update = function(event) {
    mouse.updatePosition(event);
    updateTransformStyle(
      percentageToDeg(mouse.y / container.clientHeight).toFixed(2),
      percentageToDeg(mouse.x / container.clientWidth).toFixed(2)
    );
  };

  //-----------------------------------------

  const onMouseEnterHandler = function(event) {
    update(event);
  };

  const onMouseLeaveHandler = function() {
    cover.style = '';
  };

  const onMouseMoveHandler = function(event) {
    if (isTimeToUpdate()) {
      update(event);
    }
  };

  //-----------------------------------------

  container.onmouseenter = onMouseEnterHandler;
  container.onmouseleave = onMouseLeaveHandler;
  container.onmousemove = onMouseMoveHandler;
};

export default { init };
